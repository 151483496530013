<template>
	<div class="grid mb-4">
		<div class="title">{{ isNew ? 'Создание рыболовного участка' : 'Рыболовный участок' }}</div>
		<form @submit.prevent class="form">
			<BaseGroup title="Основные данные" class="">
				<BaseInput v-model="formData.name" placeholder="Наименование" :required="true" :minlength="3"/>
				<BaseInput v-model="formData.nameShort" placeholder="Короткое наименование"/>
			</BaseGroup>

			<div class="form--controls">
				<BaseButton @click="addRegion" :disabled="formIsInvalid"> Сохранить</BaseButton>
				<BaseButton v-if='!isNew' view="danger" @click="deleteRegion"> Удалить</BaseButton>
			</div>
		</form>
	</div>
</template>

<script>
import { onMounted, ref, watch, nextTick } from 'vue'
import BaseGroup from '@/components/ui/BaseGroup'
import BaseInput from '@/components/ui/BaseInput'
import BaseButton from '@/components/ui/BaseButton'
import { useApi } from '@/modules/api'
import { useRoute, useRouter } from 'vue-router'
import { useAlert } from '@/modules/composition/useAlert'

export default {
	name: 'BoxesNew',
	components: {
		BaseGroup,
		BaseInput,
		BaseButton
	},
	setup () {
		const { errorAlert } = useAlert()
		const route = useRoute()
		const router = useRouter()
		const formData = ref({
			name: null,
			nameShort: null
		})
		const isNew = route.name === 'RegionNew'
		const formIsInvalid = ref(true)

		watch(formData, async () => {
			await nextTick()
			formIsInvalid.value = document.querySelectorAll(':invalid').length > 0
		},
		{ deep: true }
		)

		const addRegion = async () => {
			const { post, error } = useApi('/dictionary/fisherRegion')

			const params = {
				...formData.value
			}

			await post(params)
			if (error.value) {
				errorAlert(`Ошибка при сохранении данных! ${error.value?.message}`)
			} else {
				await router.push('/regions')
			}
		}

		const deleteRegion = async () => {
			const { del, error } = useApi(`/dictionary/fisherRegion${route.params.id}`)
			await del()

			if (error.value) {
				errorAlert(`Ошибка при удалении данных! ${error.value?.message}`)
			} else {
				await router.push('/regions')
			}
		}

		onMounted(async () => {
			if (!isNew) {
				const { get, data } = useApi(`/dictionary/fisherRegion/${route.params.id}`)

				await get()

				formData.value = data.value
			}
		})

		return {
			isNew,
			formData,
			formIsInvalid,
			addRegion,
			deleteRegion
		}
	}
}
</script>

<style lang="scss" scoped>
.form {
	&--controls {
		margin-top: 20px;

		.button {
			width: 120px;

			&:first-child {
				margin-right: 10px;
			}
		}
	}
}
</style>
